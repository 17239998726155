@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {

  /* ion-content > div {
    @apply lg:max-w-5xl lg:mx-auto lg:w-full
  } */

  .primary-button {
    @apply cursor-pointer block p-3 font-medium text-center text-white bg-black rounded dark:text-black dark:bg-white active:bg-gray-700 dark:active:bg-gray-300 disabled:opacity-25;
  }

  .primary-outlined-button {
    @apply cursor-pointer block p-3 font-medium text-center text-black border border-black border-solid rounded dark:text-white dark:border-white active:bg-black dark:active:bg-white disabled:opacity-25 active:bg-opacity-5 dark:active:bg-opacity-5;
  }

  .secondary-button {
    @apply cursor-pointer block p-3 font-medium text-center bg-gray-100 rounded active:bg-gray-200 dark:bg-gray-800 dark:active:bg-gray-700 disabled:opacity-25;
  }

  .pill-button {
    @apply cursor-pointer block px-8 py-1.5 font-medium uppercase border border-black border-solid rounded-full text-xxs dark:border-white border-opacity-30 dark:border-opacity-70 active:bg-gray-100 dark:active:bg-gray-900 disabled:opacity-25
  }

  .link-button {
    @apply cursor-pointer block p-2 active:bg-gray-100 dark:active:bg-gray-900;
  }

  .official-activity-button {
    @apply cursor-pointer block p-2 border-l-2 border-purple-600 border-solid dark:border-purple-400 active:bg-gray-100 dark:active:bg-gray-900;
  }

  .over-budget-button {
    @apply cursor-pointer block p-2 border-l-2 border-red-600 border-solid dark:border-red-400 active:bg-gray-100 dark:active:bg-gray-900;
  }

  .low-budget-button {
    @apply cursor-pointer block p-2 border-l-2 border-yellow-600 border-solid dark:border-yellow-400 active:bg-gray-100 dark:active:bg-gray-900;
  }

  .black-button {
    @apply block p-3 font-medium text-center text-white bg-black rounded cursor-pointer active:bg-gray-800 disabled:opacity-25
  }

  .checkout-button {
    @apply cursor-pointer block p-3 font-medium text-center text-white bg-purple-600 rounded dark:text-black dark:bg-purple-400 active:bg-purple-500 dark:active:bg-purple-300 disabled:opacity-25;
  }

  .danger-button {
    @apply cursor-pointer block p-3 font-medium text-center text-white bg-red-600 dark:bg-red-500 rounded dark:text-black active:bg-red-500 dark:active:bg-red-400 disabled:opacity-25
  }

  .safe-area-inset-top-padding {
    padding-top: max(env(safe-area-inset-top), 12px);
  }

  .safe-area-inset-bottom-padding {
    padding-bottom: max(env(safe-area-inset-bottom), 12px);
  }

  .safe-area-inset-top-margin {
    margin-top: max(env(safe-area-inset-top), 12px);
  }

  .safe-area-inset-bottom-margin {
    margin-bottom: max(env(safe-area-inset-bottom), 12px);
  }

  .album-gradient {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  /* Horizontal Scroll */

  .horizontal-scroll {
    @apply grid;
    grid-column: 1 / -1;
    /* grid-gap: 12px; */
    grid-template-columns: 4px;
    grid-auto-columns: min-content;
    grid-auto-flow: column;
    overflow-x: scroll;
    scroll-snap-type: x proximity;
    /* transition: mask-position .25s,mask-size .25s;
        mask-image: linear-gradient(90deg,transparent 0,#000 150px,#000 90%,transparent);
        mask-size: calc(100% + 40px);
        mask-position: -20px; */
    scroll-behavior: smooth;
  }

  .no-scrollbar {
    scrollbar-width: none;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .horizontal-scroll:before,
  .horizontal-scroll:after {
    content: "";
    /* width: 4px; */
  }

  .horizontal-bar:before {
    content: "";
    position: absolute;
    border-bottom-width: 1px;
    border-bottom-color: #e4e4e4;
    border-bottom-style: solid;
    top: 50%;
    right: 100%;
    width: 5000px;
  }

  .horizontal-bar:after {
    content: "";
    position: absolute;
    border-bottom-width: 1px;
    border-bottom-color: #e4e4e4;
    border-bottom-style: solid;
    top: 50%;
    left: 100%;
    width: 5000px;
  }

  /* End Horizontal Scroll */

  .modal-full-width {
    --width: 100%;
    --height: 100%;
  }

  .reactive-logo > path {
    fill: currentColor
  }

  .desktop-container {
    @apply max-w-[1024px] mx-auto;
  }
}
