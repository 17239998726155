.alert-button.alert-cancel-button {
  color: var(--ion-color-secondary)
}

.alert-button.alert-delete-button:last-child {
  font-weight: unset;
}

.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

ion-tab-bar {
  border-top-width: 1px;
}

ion-footer .full-footer-mobile-toolbar-padding:last-of-type {
  padding: 8px;
  padding-bottom: env(safe-area-inset-bottom);
}

ion-footer .full-footer-toolbar-padding:last-of-type {
  padding: 8px;
}

.md ion-alert {
  --background: var(--ion-color-step-100)
}

.hosted-field {
  border-bottom: 1px solid rgba(0, 0, 0, 0.23);
  /* border-radius: 4px; */
}

.hosted-field:hover {
  border-bottom: 2px solid black;
}


.braintree-hosted-fields-focused {
  /* border-color: black; */
  border-bottom: 2px solid black;
  /* box-shadow: 0 0 0 1px rgba(0, 0, 0, 1); */
}

.hosted-field-invalid {
  border-color: #DC2626;
  /* box-shadow: 0 0 0 1px #DC2626; */
}

@media (prefers-color-scheme: dark) {
  ion-tab-bar {
    border-top-width: 0px;
  }
}